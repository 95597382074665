<template>
    <div>
        <!-- PC端 -->
        <div class="pc-style">
            <div class="cycle-content">
                <div class="con-title">运维管理云平台</div>
                <div class="con-banner">
                    <el-carousel id="el-carousel1" class="cycle-carousel3" indicator-position="none" :interval="5000" arrow="never" ref="slideCarousel3" @change="changeSlide3">
                        <el-carousel-item class="carousel3-item" v-for="(item,index) in bannerImgs" :key="index" :name="'a'+index">
                            <img :src="item.url" class="banner-img"/>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="con-banner-text">
                    <span :class="currentIndex=='a'+index?'acitve-text':''" @click="toggleBanner('a'+index)" v-for="(item,index) in bannerText" :key="index">
                        {{item.name}}
                    </span>
                </div>
            </div>
        </div>
        <!-- 移动端 -->
        <div class="app-style">
            <div class="cycle-content">
                <div class="con-title">运维管理云平台</div>
                <div class="con-product" v-for="(item, index) in appProducts" :key="index">
                    <img :src="item.url" class="banner-img"/>
                    <p>{{item.name}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            bannerImgs: [
                { 
                    name: '巡检管理',
                    url:require("@/img/operation-cycle.jpg")
                },
                { 
                    name: '点检管理',
                    url:require("@/img/operation-check.jpg")
                },
                { 
                    name: '检修管理',
                    url:require("@/img/operation-overhaul.jpg")
                },
                { 
                    name: '润滑管理',
                    url:require("@/img/operation-lubrication.jpg")
                },
            ],
            bannerText: [
                {name:'巡检管理'},
                {name:'点检管理'},
                {name:'检修管理'},
                {name:'润滑管理'},
            ],
            currentIndex: 'a'+0,
            appProducts: [
                {
                    name: '巡检管理',
                    url:require("@/img/operation-cycle.jpg")
                },
                { 
                    name: '点检管理',
                    url:require("@/img/operation-check.jpg")
                },
                { 
                    name: '检修管理',
                    url:require("@/img/operation-overhaul.jpg")
                },
                { 
                    name: '润滑管理',
                    url:require("@/img/operation-lubrication.jpg")
                },
            ]
        }
    },
    methods: {
        toggleBanner(index) {
            this.currentIndex = index
            this.$refs.slideCarousel3.setActiveItem(index)
        },
        changeSlide3(e) {
            this.currentIndex = 'a'+e
        },
    },
    mounted() {

    }
}
</script>
<style lang="less" scope>
.banner-img {
    width: 100%;
}
.cycle-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    .con-title {
        font-size: 28px;
        font-weight: bold;
    }
    .con-banner {
        width: 60%;
        margin: 20px 0;
        .cycle-carousel3 {
            border: 2px solid #FFFFFF;
        }
    }
    .con-banner-text {
        span {
            display: inline-block;
            width: 200px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border: 2px solid #FFFFFF;
            border-radius: 10px;
            margin: 0 30px;
            font-size: 18px;
            cursor: pointer;
            transition: all .3s ease;
        }
        span:hover {
            background: #FFFFFF;
            color: #00175B;
        }
        .acitve-text {
            background: #FFFFFF;
            color: #00175B;
        }
    }
}
.app-style { display: none; }

// 移动端样式
@media screen and (max-width:650px) {
    .pc-style { display: none; }
    .app-style { display: block; }
    .con-title {
        font-size: 18px !important;
        margin: 30px 0;
    }
    .con-product {
        margin: 10px 0 0;
        p {
            text-align: center;
            font-size: 14px;
            margin-bottom: 35px;
        }
    }
}
</style>